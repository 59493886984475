import React from "react"

import { login, isAuthenticated } from "../utils/auth"
import LayoutDrawer from "../components/LayoutDrawer"
// import "../styles/styles.scss"

const Docs = () => {
  if(!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  return (
      <main>
        <title>Documentation</title>
        <LayoutDrawer sidebar>
          <h1 className="pageTitle">Practice Alpha Documentation</h1>
        </LayoutDrawer>
      </main>

  )
}

export default Docs